import React from "react";
import styled from "styled-components";

export const MDWrapper = styled.div`
    color: rgba(39, 39, 42, 1);
    h2 {
        font-weight: 700;
    margin-bottom: 2px;
    }
    h1 {
        font-weight: 700;
    margin-bottom: 16px;
    }
    p, ul {
    margin-bottom: 16px;
    font-size: 18px;
    }
    a {
           color: #2196f3;
               text-decoration: auto;
           border-bottom: 1px solid;
    }
`;

export function BlogMDWrapper({children}) {

    return <MDWrapper className="prose lg:prose-xl mx-auto">
        {children}
    </MDWrapper>
}