import React from "react";

const tc_order = ['acceptance','compliance','security','usability','scalability'];

export const testing_categories = {
    'acceptance': {
        title: 'Acceptance',
        subtitle: '',
        examples: {
            functional: 'Xx',
            non_functional: 'Xx'
        }
    },
    'product': {
        title: 'Product',
        subtitle: '',
        parent: 'acceptance',
        examples: {
            functional: 'can i upload an image for my profile picture?',
            non_functional: 'Xx'
        }
    },
    'use_case': {
        title: 'Use Case',
        subtitle: '',
        parent: 'acceptance',
        examples: {
            functional: 'can i send a message to all my employees',
            non_functional: 'Xx'
        }
    },
    'compliance': {
        title: 'Compliance',
        subtitle: '',
        examples: {
            functional: 'are the latest Terms and Conditions displayed?',
            non_functional: 'Xx'
        }
    },
    'security': {
        title: 'Security',
        subtitle: '',
        examples: {
            functional: 'can a script tag be posted to the feed?',
            non_functional: 'test for Cross-site request forgery (CSRF) vulnerabilities'
        }
    },
    'dependencies': {
        title: 'Dependencies',
        subtitle: '',
        parent: 'security',
        examples: {
            functional: 'check that no packages have vulnerabilities',
            non_functional: ''
        }
    },
    'usability': {
        title: 'Usability',
        subtitle: '',
        examples: {
            functional: 'Xx',
            non_functional: 'is it easy to find the create group button?'
        }
    },
    'ease_of_use': {
        title: 'Ease-of-use',
        subtitle: '',
        parent: 'usability',
        examples: {
            functional: '(advanced) can i create a group in less than 10 seconds?',
            non_functional: 'is it clear what needs to be filled out in the form before the create button is active?'
        }
    },
    'compatibility': {
        title: 'Compatibility',
        subtitle: '',
        parent: 'usability',
        examples: {
            functional: 'is the create group button rendering on screen for all device sizes?',
            non_functional: 'can i find and access menus across many tablets/mobile devices?'
        }
    },
    'performance': {
        title: 'Performance',
        subtitle: '',
        parent: 'usability',
        examples: {
            functional: 'is the latency under 100ms for requests?',
            non_functional: 'is the rendering slowing down interactions and creating bad UX'
        }
    },
    'scalability': {
        title: 'Scalability',
        subtitle: '',
        examples: {
            functional: 'does the app still work at 10k users?',
            non_functional: 'are emails/sms received fast enough?'
        }
    }
};

function buildRows(tc) {
    return [
        ...tc_order.map(id=>{
            const data = tc[id];
            const subcategories = Object.values(tc).filter((it)=>{
                return it.parent === id;
            });
            console.log('id',id,subcategories)
            return {
                title: data.title,
                subtitle: data.subtitle,
                subcategories: (subcategories?subcategories.map(sc=>{
                    return {
                        title: sc.title,
                        subtitle: sc.subtitle
                    }
                }):null),
                columns: [
                    [
                        // functional
                        data.examples.functional,
                        ...(subcategories?subcategories.map(sc=>{
                            return sc.examples.functional
                        }):[])
                    ],
                    [
                        data.examples.non_functional,
                        ...(subcategories?subcategories.map(sc=>{
                            return sc.examples.non_functional
                        }):[])
                    ]
                ]
            }
        })
    ]
}

export function TypesOfTesting({
    headers = [
        {title: 'Types'},
        {title: 'Functional',subtitle:'..if it works'},
        {title: 'Non-functional',subtitle:'..how it works'}
    ]
                               }) {

    const rows = buildRows(testing_categories);

    return <div className="border-gray-200 text-base text-gray-700 shadow-sm m-4">
        <div className="grid grid-cols-3 border-t border-gray-200 border-b rounded-t-md border-l border-r">
            {headers.map((h,o)=><div className={`p-4 font-semibold text-gray-700 border-gray-200 ${(o+1)===headers.length?"":"border-r"}`} key={h}>
                <div>{h.title}</div>
                {h.subtitle&&<div className="text-xs text-gray-500 font-medium italic">{h.subtitle}</div>}
            </div>)}
        </div>
        <div className="">
        {rows.map((row,n)=><div key={row.title} className={`border-b ${(n%2)===0?"bg-gray-50":""} ${(n+1)===rows.length?"rounded-b-md":""} grid border-l border-r border-gray-200 grid-cols-3`}>
            <div className={`p-4 border-r border-gray-200 space-y-3`}>
                <div className=" h-20">
                <div className="text-gray-800 text-base font-bold">{row.title}</div>
                <div className="text-sm text-gray-600">{row.subtitle}</div>
                </div>
                {row.subcategories&&row.subcategories.map((sc,t)=><div className="h-16" key={t}>
                    <div className="text-sm font-bold text-gray-700">{sc.title}</div>
                    <div className="text-gray-600 text-xs">{sc.subtitle}</div>
                </div>)}
            </div>
            {row.columns.map((col,m)=><div className={`p-4 ${(m+1)===row.columns.length?"":"border-r"} space-y-3 border-gray-200`} key={`${m}`}>
                {col.map((c,s)=><div className={`${s===0?"h-20":"h-16"} text-gray-600 italic`} key={`${s}-${c}`}>
                    {c}
                </div>)}
            </div>)}
        </div>)}
        </div>
    </div>
}