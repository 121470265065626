import React, {useState} from "react";
import styled from "styled-components";
import {Popover} from "../popover";

const testing_config = {
    why_we_dont_test: [
        {
            title: 'implicitly unimportant, we get paid to code'
        },
        {
            title: 'it can be tedious and can slow down dev in the short term, at times when speed and features can be the most important thing'
        }
    ],

    why_we_test: [
        {
            title: 'confidence that code does what it should'
        },
        {
            title: 'feedback for devs'
        },
        {
            title: 'less maintanence work'
        }
    ],

    goals: [
        {
            title: 'speed, stability, reliability'
        },
        {
            title: 'move faster! speed of feedback cycle'
        },
        {
            title: 'broad defense for our users, just like with ants, need multi-faceted approach'
        }
    ],

    principles: [
        {
            title: 'invest as much time as we must and no more - do not test the trivial'
        },
        {
            title: 'do not automate from day one, we cannot automate everything'
        },
        {
            title: 'bugs will still happen'
        }
    ],

    // when does testing happen?
    testing_buckets: {
        // functional only
        // difference between unit and functional -> unit only tests a localized piece, but still if it works
        // stick with that distinction
        'product_release_testing': {},
        // functional and non-functional? (usability, security), all manual
        'development_branch_testing': {},
        // both non-functional and functional
        // big picture testing
        // important but not everyday
        // not ad hoc
        // as needed
        // manual or automated - security, usability, compliance
        // compliance (beacons are firing correctly)
        // security
        'exploratory_product_testing': {}
    },

    // logic breakdown
    /*
    there are functional and non-functional tests
    functional - does it work?
    non-functional - how does it work?

    both functional and non-functional tests can either be manual or automated

    are all acceptance tests?

    types of tests -> feature, security, usability, compliance (legal/privacy), compatibility

    // examples for a social app like facebook
    feature -> can i create a group?
        capability -> can i upload an image for my profile picture?
        compliance -> are the latest Terms and Conditions displayed?
    security -> can a script tag be posted to the feed?
        dependencies -> packages that have posted vulns
        vulnerabilities -> can cross-site scripting be used to attack, iframe not protected with csp?
    usability -> is it easy to find the create group button? (traditionally manually only)
        ease-of-use -> is it understood that the group create form is disabled until all fields are filled?
        compatibility -> does the create group button render on all relevant devices (tablet/mobile)?
    scalability -> can this handle 10k users?

    types of automated tests -> unit, component, integration, service, system (e2e)

    // examples
    unit -> tests a single function without dependencies e.g. validate user handle is valid function
    component -> tests a single set of functions within one subset of functions (usually grouped through a feature) without depending on other parts of the application or other apps
        ui -> does the group form work as expected with validation?
        local-flow -> tests multiple functions that accomplish one thing, e.g. handling an uploaded photo, cropping it, and saving it to the state so it can be displayed
        provider -> mock returning an output which would go to another external service (microservices
        consumer -> mock accepting an input from another external service, e.g. testing create full user functions with mock input
    integration -> tests multiple components or apps to ensure things work when joined together
        service -> connect to a live endpoint of a different app e.g. social graph
        global-flow -> does a full user flow work? e.g. can a full user be created by combining a ui test to fill out a form, then calling the backend app to create the actual user

    // it almost seems like system testing is a catch all or parent term
    system -> also known as end-to-end test, these flow across two or more apps
     */

    terms: {
        'support_request': {
            title: 'Support Request',
        },
        'user_story': {
            title: 'User Story',
        },
        'user_groups': {
            title: 'User Groups'
        },
        'use_cases_map': {
            title: 'Use Cases Map',
        },
        'release_test_suite': {
            title: 'Release Test Suite',
        },
        'bug_bash': {
            title: 'Bug Bash'
        },
        'functional_test': {
            title: 'Functional Test',
            subtitle: '"if it works"'
        },
        'non_functional_test': {
            title: 'Non-functional Test',
            subtitle: '"how it works"'
        },
        'smoke_test': {
            title: 'Smoke Test',
            subtitle: 'critical acceptance tests that would be release blockers'
        },
        'component_test': {
            title: '',
            subtitle: ''
        }, // grouping of automated test types (provider, consumer, ui, flow) that tests multiple functions within the context of one subset of functions usually a feature in one app without depenedencies
        'e2e_test': {
            title: '',
            subtitle: ''
        }, // automated test type in the integration group, that usually tests a full feature or use case end-to-end maybe across multiple apps
        'unit_test': {
            title: '',
            subtitle: ''
        }, // simplest form of a test to test one function
        'anomoly_detection': {
            title: '',
            subtitle: ''
        }, //
        'scalability_test': {
            title: '',
            subtitle: ''
        }, //
        'integration_test': {
            title: '',
            subtitle: ''
        }, // grouping of tests for more complex, full tests for a given system
        'service_test': {
            title: '',
            subtitle: ''
        }, // in the integration group of automated tests, used to test a single endpoint
        'system_test': {
            title: '',
            subtitle: ''
        }, // grouping term for all the automated testing that happens for a given system
        'dog_fooding': {
            title: '',
            subtitle: ''
        },
        'fish_fooding': {
            title: '',
            subtitle: ''
        },
        'code_coverage': {
            title: '',
            subtitle: ''
        }, // unit, component, integration testing is covered through this
        'ci/cd': {
            title: 'CI/CD',
            subtitle: 'continous integration / continuous development'
        },
        'test_sequence': {
            title: 'Test Sequence',
            subtitle: 'a grouped subset of test cases within a test suite'
        }, // multiple test cases done in succession
        'provider_test': {
            title: '',
            subtitle: ''
        }, //
        'consumer_test': {
            title: '',
            subtitle: ''
        }, //
        'test_case': {
            title: '',
            subtitle: ''
        }, // single test typically in acceptance case formatting
        'security_testing': {
            title: '',
            subtitle: ''
        }, // csp, vulnerabilities
        'performance_testing': {
            title: '',
            subtitle: ''
        }, // latency, render times
        'compliance_testing': {
            title: '',
            subtitle: ''
        }, //
        'usability_testing': {
            title: '',
            subtitle: ''
        }, //
        'test_suite': {
            title: 'Test Suite',
            subtitle: 'either platform, product, feature'
        },
        'test_plan': {
            title: 'Test Plan',
            subtitle: 'list of strategy and test suites used to test a platform, product, or feature'
        },
        'test_harness': {
            title: 'Test Harness',
            subtitle: ''
        }, //
        'testing_framework': {
            title: 'Testing Framework',
            subtitle: ''
        }, //
        'acceptance_test': {
            title: 'Acceptance Test',
            subtitle: ''
        }, //
        'ticket_review_queue': {
            title: 'Ticket Review Queue',
            subtitle: ''
        },
        'user_persona': {
            title: 'User Persona',
            subtitle: ''
        },
        'compatibility_testing': {
            title: '',
            subtitle: ''
        }, //
        'definition_of_done': {
            title: 'Definition of Done',
            subtitle: ''
        },
        'acceptance_criteria': {
            title: 'Acceptance Criteria',
            subtitle: '',
        },
        'gherkin': {
            title: 'Gherkin',
            subtitle: 'A plain text language built around a simple set of rules to add structure to test cases. Designed to be easy to learn and adopt for non-developers.',
            how_to_use: <div className="prose prose-sm p-3 border border-gray-200 rounded-md bg-gray-50">
                <ul>
                    <li>Test cases are written around your defined user stories</li>
                    <li>Each story will have one or more test cases which are built around "scenarios"</li>
                    <li>Use the keywords GIVEN, WHEN, and THEN to describe your scenario</li>
                    <li>AND and BUT can also be used to add context to the scenario</li>
                    <li>Note: GIVEN, WHEN, and THEN all have one indent, AND and BUT have two</li>
                    <li>The Scenario describes the case -> "Scenario: Trial User hasn't reached monthly limit"</li>
                    <li>GIVEN lets you setup your scenario -> "GIVEN the user has sent 30 messages this month"</li>
                    <li>WHEN is the action in your test case -> "WHEN the message is sent"</li>
                    <li>THEN is your assertion of what should happen -> "user should get a confirmation of sending"</li>
                </ul>
            </div>,
            example: <div className="prose prose-sm p-3 border border-gray-200 rounded-md bg-gray-50">
                <div>
                    <strong>Feature: User sends a message</strong>
                </div>
                <div>
                    <strong>Scenario: Trial User hasn't reached monthly limit</strong>
                    <div>&emsp;GIVEN the user has sent 30 messages this month</div>
                    <div>&emsp;&emsp;AND the monthly free limit is 100</div>
                    <div>&emsp;&emsp;AND they are sending a valid message</div>
                    <div>&emsp;WHEN the message is sent</div>
                    <div>&emsp;THEN user should get a confirmation of sending</div>
                    <div>&emsp;&emsp;AND the message should appear in the current chat</div>
                </div>
            </div>,
            related_terms: [
                {
                    id: 'user_story',
                    title: 'One or more Gherkin tests are written per user story during the development cycle.'
                }
            ]
        },
        'bdd': {
            title: 'Behavior Driven Development',
            subtitle: 'A practical approach to writing tests in plain text through examples.',
            related_terms: [
                {
                    id: 'acceptance_criteria',
                    title: 'These examples are written before feature development begins and are used as acceptance criteria.'
                },
                {
                    id: 'definition_of_done',
                    title: 'A feature being "done" includes fulfilling all defined test examples.'
                }
            ]
        }, //
        'tdd': {
            title: 'Test Driven Development',
            subtitle: ''
        },
        'exploratory_product_testing': {
            title: '',
            subtitle: ''
        }, //
        'product_release_testing': {
            title: '',
            subtitle: ''
        }, //
        'development_branch_testing': {
            title: '',
            subtitle: ''
        }
    },

    tools: {
        'task_management_tool': {
            title: 'Task Management Tool'
        },
        'release_planner': {
            title: 'Release Planner'
        },
        'bug_bash_template': {
            title: 'Bug Bash Template'
        },
        'use_case_mapper': {
            title: 'Use Case Mapper'
        },
        'loom': {
            title: 'Loom'
        },
        'splunk': {
            title: 'Splunk'
        },
        'defect_template': {
            title: 'Defect Template'
        },
        'release_plan': {
            title: 'Release Plan'
        },
        'acceptance_test_template': {
            title: 'Acceptance Test Template'
        },
        'jira': {
            title: 'JIRA'
        },
        'github': {
            title: 'GitHub'
        },
        'custom_test_type_checklist': {
            title: 'Custom Test Type Checklist'
        },
        'zephyr': {
            title: 'Zephyr'
        },
        'selenium': {
            title: 'Selenium'
        },
        'test_categories': {
            title: 'Test Categories'
        },
        'automated_test_types': {
            title: 'Automated Test Types'
        },
        'appium': {
            title: 'Appium'
        }
    },

    phases_order: [
        'starter',
        'essential',
        'professional',
        'enterprise'
    ],

    phases: {
        'starter': {
            title: 'Starter',
            emoji: '🚴‍♂️',
            subtitle: `For teams just getting started with a small feature set and user base.`,
            goals: [
                {
                    title: 'Keep it simple! Make sure the critical user flows are tested often and that your whole team the can test these flows.',
                },
                {
                    title: 'Put a solid process foundation in place for your team with quality assurance (QA) and testing so you can grow faster.'
                }
            ],
            principles: [
                {
                    title: 'Testing should be simple and focused on stability and reliability of the most important functionality.',
                },
                {
                    title: 'Teams should distinguish between product and feature testing - features and bugs should be tested first on branches before they are merged to the master branch.'
                },
                {
                  title: 'Each product should then have a set of standard tests (test suite) that are conducted each time a release goes out.'
                },
                {
                    title: 'At this stage, all your testing should be manual, automation will come soon enough.'
                }
            ],
            implement: [
                {
                    title: 'Define all your products and their user personas, use cases, and features',
                    actions: [
                        {
                            title: 'Create a shared understanding of your products',
                            subtitle: 'Your Product team should provide a list of user groups using your products and their use cases and features that are important.'
                        },
                        {
                            title: 'Define product responsibilities',
                            subtitle: 'Ensure a clear Engineering and QA lead are defined for each product and that that is communicated to all teams.'
                        },
                        {
                            title: 'Get into a regular product release cadence',
                            subtitle: 'To speed up development and ensure QA and engineering can work well together, establish semi-frequent release dates signed off by engineering and QA.'
                        }
                    ],
                    best_practices: [
                        {
                            title: 'Understand and document how your products are used by different people and build user personas around them with their main flows/use cases in each product'
                        },
                        {
                            title: 'When developing new features, each feature should include of one or more user stories that can later be used to create test cases for the feature.'
                        }
                    ],
                    terms: [
                        'user_groups',
                        'user_story',
                        'user_persona',
                        'use_cases_map'
                    ],
                    tools: [
                        'use_case_mapper'
                    ]
                },
                {
                    title: 'Write test cases to cover critical functionality, these are your smoke tests',
                    actions: [
                        {
                            title: 'Create a central QA materials repository',
                            subtitle: 'A Google Drive folder or Dropbox folder would do fine!'
                        },
                        {
                            title: 'Write your first test cases',
                            subtitle: 'Write test cases, ideally in BDD format using Gherkin, for the most important features defined by product.'
                        },
                        {
                            title: 'Gather Testing Materials',
                            subtitle: 'Place any helpful materials for testing in the your folder/repository, so anyone can do testing as needed.'
                        }
                    ],
                    best_practices: [
                        {
                            title: 'Use the Behavior Driven Development (BDD) approach to write tests in the Gherkin format using real-world examples'
                        },
                        {
                            title: 'When writing test cases, consider the various user personas to capture relevant scenarios for features and use cases',
                        },
                        {
                            title: 'Also consider external apps and environment settings when writing test cases like emails and main devices/browsers',
                        },
                        {
                            title: 'Ensure your entire team gets exposure to BDD and Gherkin so that designers/engineers/product managers get a better understanding of how to frame features and requirements'
                        }
                    ],
                    terms: [
                        'smoke_test',
                        'bdd',
                        'gherkin',
                    ],
                    tools: [
                        'acceptance_test_template',
                        'test_categories'
                    ]
                },
                {
                    title: 'Define the defect reporting and QA handoff process',
                    actions: [
                        {
                            title: 'Build your defect template',
                            subtitle: 'Ask engineering what information they need to better debug any issues that come up, define that template and share with the whole team.'
                        },
                        {
                            title: 'Create Engineering/QA handoff process document',
                            subtitle: 'Simple but important: when fixing a bug or developing a feature, when do developers assign tickets to QA? Does QA directly close the ticket if done?'
                        },
                        {
                            title: 'Create Bug Bash Spreadsheet Template',
                            subtitle: 'During the development of a bigger feature, it makes sense to do bug bashes with a larger group but not to create tickets for everything found right away in your task management tool. Create a spreadsheet template that works for your teams likely listing issues with a description, priority, reporter, and severity as well as additional context.'
                        },
                    ],
                    best_practices: [
                        {
                            title: 'Create a defect/bug template that captures the info that will help developers debug like device, browser, screenshots, and anything else. We find videos/looms with audio descriptions work best for most teams!'
                        },
                        {
                          title: 'Assign a formal Engineering lead and QA lead for each product as a single point of contact for Development/QA needs.'
                        },
                        {
                            title: 'Ensure the handoff process is defined between engineering and QA and is captured in your task management system. Typically this means engineering setting tickets as "Ready for Review" and assigning the reviewer as the product QA lead.'
                        },
                        {
                            title: 'Create a defect reporting template in your task management tool and create a public shortlink url to it for your whole team.'
                        },
                        {
                            title: 'For bug bashes during feature development, create a defect reporting template in Google Sheets or Excel so many issues can be quickly captured and prioritized before defects are created from discoveries.'
                        }
                    ],
                    terms: [
                        'bug_bash',
                        'support_request',
                        'ticket_review_queue'
                    ],
                    tools: [
                        'defect_template',
                        'bug_bash_template',
                        'task_management_tool',
                        'loom'
                    ]
                },
                {
                    title: 'Create product test suites and align release cycles for all products',
                    actions: [
                        {
                            title: 'Build a product release test suite',
                            subtitle: 'Choose the test cases that should be tested every time a release for a product goes out, this is now your product release test suite. This should be the same set of tests every week so important regressions can be discovered.'
                        },
                        {
                            title: 'Define your Smoke Tests',
                            subtitle: 'Work with your product and engineering teams to mark critical tests on your general product test suite as "Smoke Tests", meaning if they fail, a release is aborted.'
                        },
                        {
                            title: 'Write tests during the product development lifecycle',
                            subtitle: 'Work with your product team so when they write PRDs, they\'re defining user stories in a way that QA can then take those and create test cases in Gherkin around easily.'
                        },
                    ],
                    best_practices: [
                        {
                            title: 'Each product should have a regular release cadence that depends on business needs but is typically at least every 4 weeks. Typically teams should aim to increase the frequency of relases over time to speed up development.',
                        },
                        {
                          title: 'Each product should have a general test suite conducted with each release.'
                        },
                        {
                            title: 'Each product should have an Engineering and a QA lead. The QA lead should give the Engineering lead the greenlight to release after testing.'
                        },
                        {
                            title: 'The whole team should understand the distinction between release and development feature testing. Development feature testing should happen on a branch before it is merged to the master branch and included in the next release.'
                        }
                    ],
                    tools: [
                        'release_planner'
                    ],
                    terms: [
                        'release_test_suite',
                        'acceptance_test'
                    ]
                }
            ],
        },
        'essential': {
            title: 'Essential',
            emoji: '🚗',
            subtitle: `For growing teams that want to move faster and break less things.`,
            goals: [
                {
                    title: 'increasingly automate, begin to minimize manual release testing to increase speed',
                },
                {
                    title: 'requirements from new features should be developed into acceptance tests'
                }
            ],
            principles: [
                {
                    title: 'every test is manual first, not every test must be automated',
                }
            ],
            implement: [
                {
                    title: 'understand your audience',
                    subtitle: 'this is a subtitle my friend',
                    best_practices: [
                        {
                            title: 'browsers, os, resolution'
                        }
                    ]
                },
                {
                    title: 'gather testing materials',
                    best_practices: []
                },
                {
                    title: 'pick automation tools',
                    best_practices: []
                },
                {
                    title: 'setup automation environment',
                    best_practices: []
                },
                {
                    title: 'prioritize tests to automate',
                    best_practices: [
                        {
                            title: 'tests to cover what you traditionally get wrong - personally and collectively'
                        }
                    ]
                },
                {
                    title: 'implement tests',
                    best_practices: [
                        {
                            title: 'Tests should be short and sweet and focused on one thing'
                        }
                    ]
                }
            ]
        },
        'professional': {
            title: 'Professional',
            emoji: '🏎️',
            subtitle: `For teams with large user bases and increasingly complex products.`,
            goals: [
                {
                    title: 'no manual testing for releases, free them up for exploratory',
                },
                {
                    title: 'start making thinking about testing a part of your dev process'
                }
            ],
            principles: [
                {
                    title: 'move to test driven development',
                },
                {
                    title: 'metrics should influence testing prioritization of devices and flows, languages'
                },
                {
                    title: 'create custom test types for your needs, experimentation with new tools'
                }
            ],
            implement: [
                {
                    title: 'Automate all smoke tests & increase release frequency'
                },
                {
                    title: 'Incorporate writing tests into development lifecycle (+ TDD)'
                },
                {
                    title: 'expand testing scope (compatibility, security)',
                    best_practices: [
                        {
                            title: 'do not test the trivial (that is what linters are for)'
                        },
                        {
                            title: 'develop types of tests and checklists to easily create them'
                        },
                        {
                            title: 'domain-specific tests (e.g. 3D / AR)'
                        }
                    ]
                },
                {
                    title: 'Systematize exploratory testing & Expand scope (compatibility, usability)'
                }
            ]
        },
        'enterprise': {
            title: 'Enterprise',
            emoji: '🚄',
            subtitle: `For large engineering groups and products used by millions.`,
            goals: [
                {
                    title: 'allow teams to move incredibly fast with robust ci and automated testing',
                },
                {
                    title: 'diagnose and triage issues automatically with integrations'
                },
            ],
            principles: [
                {
                    title: 'anomoly detection after releases',
                },
                {
                    title: 'focus on feedback cycle',
                },
            ],
            implement: [
                {
                    title: 'Structural Improvements to feedback cycle'
                },
                {
                    title: 'Increase scope of automated testing'
                },
                {
                    title: 'setup anomoly detection',
                    best_practices: [
                        {
                            title: 'define conrollable input metrics'
                        }
                    ]
                },
                {
                    title: 'automated performance testing',
                    best_practices: [
                        {
                            title: 'Performance impact of changes (latency, memcache time, processor time, render time, etc)'
                        }
                    ]
                },
                {
                    title: 'Chaos Monkeys'
                }
            ]
        },
    }

};

function ToolCard({title = "Todo", how_to_use = null, example = null, related_terms = [], subtitle = ""}) {
    return <div className="bg-white rounded-md shadow px-3 py-3">
        <div className="text-base font-semibold text-gray-800">
            {title}
        </div>
        <div className="text-sm text-gray-600">
            {subtitle}
        </div>
        {(!!how_to_use || !!example) && <div className="grid pt-4 grid-cols-2 gap-4">
            {!!how_to_use && <div className="space-y-2">
                <UppercaseTitle title="How to Use"/>
                <div>
                    {how_to_use}
                </div>
            </div>}
            {!!how_to_use && <div className="space-y-2">
                <UppercaseTitle title="Example"/>
                <div>
                    {example}
                </div>
            </div>}
        </div>}
        {related_terms.length > 0 && <div className="pt-4 flex space-x-4">
            <div className="flex-none pt-0.5">
                <UppercaseTitle title="Related"/>
            </div>
            <div className="flex-grow flex space-x-2 text-sm font-medium text-gray-700">
                {related_terms.map((rt, k) => {
                    const item = testing_config.terms[rt.id];
                    return <div key={rt.id}>
                        <a>{item ? item.title : rt.id}</a>
                    </div>
                })}
            </div>
        </div>}
    </div>
}

function TermCard({title = "", related_terms = [], how_to_use = null, example = null, subtitle = ""}) {
    return <div className="bg-white max-w-4xl rounded-md shadow px-3 py-3">
        <div className="text-base font-semibold text-gray-800">
            {title}
        </div>
        <div className="text-sm text-gray-600">
            {subtitle}
        </div>
        {(!!how_to_use || !!example) && <div className="grid pt-4 grid-cols-2 gap-4">
            {!!how_to_use && <div className="space-y-2">
                <UppercaseTitle title="How to Use"/>
                <div>
                    {how_to_use}
                </div>
            </div>}
            {!!how_to_use && <div className="space-y-2">
                <UppercaseTitle title="Example"/>
                <div>
                    {example}
                </div>
            </div>}
        </div>}
        {related_terms.length > 0 && <div className="pt-4 flex space-x-4">
            <div className="flex-none pt-0.5">
                <UppercaseTitle title="Related"/>
            </div>
            <div className="flex-grow flex space-x-2 text-sm font-medium text-gray-700">
                {related_terms.map((rt, k) => {
                    const item = testing_config.terms[rt.id];
                    return <div key={rt.id}>
                        <a>{item ? item.title : rt.id}</a>
                    </div>
                })}
            </div>
        </div>}
    </div>
}

const List = styled.ul`
  margin: 0 !important;

  > li {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }
`;

function TSCSection({title = "Goals", items = []}) {

    return <div>
        <div className="font-semibold text-gray-700 text-lg">
            {title}
        </div>
        <div className="-mt-1 prose prose-sm">
            <List className="">
                {items.map(it => <li className="" key={it.title}>{it.title}</li>)}
            </List>
        </div>
    </div>
}

function PrimaryButton({text="",onClick=()=>{}}) {
    return <div className={`bg-blue-500 text-sm font-medium rounded-md text-white hover:bg-blue-700 cursor-pointer px-4 py-1.5`} onClick={onClick}>
        {text}
    </div>
}

function TestingStageCard({title, emoji, selected = false, selectCard, subtitle, goals, principles, implement}) {

    return <div onClick={() => selectCard()}
                className={`space-y-6 bg-white pt-6 pb-4 transform transition-transform px-4 cursor-pointer ${selected ? "shadow-lg" : "shadow-sm hover:-translate-y-1"} rounded-md border border-gray-200`}>
        <div className="flex flex-col items-center">
            <div className="flex text-7xl items-center justify-center h-16 w-28 rounded-md">
                <span>
                {emoji}
                    </span>
            </div>
            <div className="mt-4 text-center">
                <div className="text-gray-800 font-bold text-2xl">{title}</div>
                <div className="text-gray-600 text-base">{subtitle}</div>
            </div>
        </div>
        {!selected&&<div className="p-1 flex justify-center">
            <PrimaryButton onClick={()=>selectCard()} text="Get Started" />
        </div>}
        <TSCSection title="Goals" items={goals}/>
        <TSCSection title="Principles" items={principles}/>
        <TSCSection title="Implement" items={implement}/>

    </div>
}

function Tab({title, active, onClick}) {
    return <div onClick={onClick}
                className={`font-medium text-sm px-2.5 py-1.5 transition-colors cursor-pointer rounded-md ${active ? "bg-gray-200" : "bg-gray-100 hover:bg-gray-200"}`}>
        {title}
    </div>
}

function UppercaseTitle({title}) {

    return <div className="text-gray-700 uppercase font-semibold text-xs">
        {title}
    </div>
}

function ImplementStep({title = '', actions=[], num = 1, terms = [], tools = [], subtitle = '', best_practices = []}) {

    return <div className="bg-white rounded-md space-x-4 shadow px-3 py-3 flex">
        <div className="flex-none w-6">
            <div
                className="h-6 w-6 flex items-center justify-center rounded-full bg-green-800 text-white text-sm font-semibold">{num}</div>
        </div>
        <div className="flex-grow">
            <div className="text-base font-semibold text-gray-800">{title}</div>
            {subtitle&&<div className="text-sm text-gray-600">{subtitle}</div>}
            <div className="pt-4 grid gap-8 grid-cols-4">
                <div className="col-span-3">
                <UppercaseTitle title="Actions"/>
                <div className="space-y-2">
                    {actions.map((act,k)=><div className="flex space-x-2" key={k}>
                        <div className="flex-none text-base text-gray-800">→</div>
                        <div className="flex-grow">
                            <div className="text-base font-semibold text-gray-800">{act.title}</div>
                            <div className="text-sm text-gray-600">{act.subtitle}</div>
                        </div>
                    </div>)}
                </div>
                </div>
                <div>
                    <UppercaseTitle title="Tools"/>
                    <div className="text-sm text-gray-600">
                        {tools.map((tool, k) => {
                            if (!testing_config.tools[tool]) {
                                return <div key={k}>
                                    <a>{tool}</a>
                                </div>
                            }
                            return <div className={""} key={k}>
                                <Popover placement="auto" content={<ToolCard {...testing_config.tools[tool]} />}>
                                    <div>
                                        <a className="underline cursor-pointer hover:opacity-60 transition-opacity">{testing_config.tools[tool].title}</a>{(k + 1) === tools.length ? "" : ", "}
                                    </div>
                                </Popover>
                            </div>
                        })}
                    </div>
                </div>
            </div>
            <div className="pt-4 grid gap-8 grid-cols-4">
                <div className="col-span-3">
                    <div className="prose">
                        <UppercaseTitle title="Best Practices"/>
                        <List className="">
                            {best_practices.map(bp => <li className="" key={bp.title}>{bp.title}</li>)}
                        </List>

                    </div>
                </div>
                <div className="space-y-4">
                    <div>
                        <UppercaseTitle title="Terms"/>
                        <div className="text-sm text-gray-600">
                            {terms.map((term, k) => {
                                if (!testing_config.terms[term]) {
                                    return <div key={k}>
                                        <a>{term}</a>
                                    </div>
                                }

                                return <div className={""} key={k}>
                                    <Popover placement="auto" content={<TermCard {...testing_config.terms[term]} />}>
                                        <div>
                                        <a className="underline cursor-pointer hover:opacity-60 transition-opacity">{testing_config.terms[term].title}</a>{(k + 1) === terms.length ? "" : ", "}
                                        </div>
                                    </Popover>
                                </div>
                            })}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
}

function Steps({items}) {
    return <div className="space-y-3">
        {items.map((st, k) => <ImplementStep num={k + 1} key={k} {...st} />)}
    </div>
}

function Tools({items = []}) {
    return <div className="space-y-4">
        {items.map((it, k) => <ToolCard key={it} {...testing_config.tools[it]} />)}
    </div>
}

function Terms({items = []}) {
    return <div className="space-y-4">
        {items.map((it, k) => <TermCard key={it} {...testing_config.terms[it]} />)}
    </div>
}

function getItemTerms(arr, it = 'terms') {
    let t = [];

    for (let i = 0; i < arr.length; i++) {
        const item = arr[i];
        if (item[it]) {
            t = t.concat(item[it]);
        }
    }

    return t;
}

export function TestGuide() {
    const [selected, setSelected] = useState('');
    const [tab, setTab] = useState('Steps');
    const selected_data = selected === '' ? {} : testing_config.phases[selected];
    const index = selected ? testing_config.phases_order.indexOf(selected) : null;
    return <div className='grid gap-4 p-4 grid-cols-4'>
        {selected === '' && testing_config.phases_order.map(ph_id => <TestingStageCard
            selectCard={() => setSelected(ph_id)} key={ph_id} {...testing_config.phases[ph_id]} />)}
        {selected !== '' && <div className="col-span-4 flex border bg-gray-50 border-gray-200 rounded-md p-4">
            <div className="flex-none w-96">
                <div className="mb-2 flex">
                    <div className="flex-grow">
                        <div className="inline-block">
                            {index > 0 &&
                            <Tab onClick={() => setSelected(`${testing_config.phases_order[(index - 1)]}`)}
                                 title={`← ${testing_config.phases[testing_config.phases_order[(index - 1)]].title}`}/>}
                        </div>
                    </div>
                    <div>
                        {(index + 1) < testing_config.phases_order.length &&
                        <Tab onClick={() => setSelected(`${testing_config.phases_order[(index + 1)]}`)}
                             title={`${testing_config.phases[testing_config.phases_order[(index + 1)]].title} →`}/>}
                    </div>
                </div>
                <TestingStageCard selected={true} selectCard={() => {
                }} {...selected_data} />
            </div>
            <div className="flex-grow pl-6">
                <div className="flex">
                    <div className="flex flex-grow space-x-2 mb-2">
                        <Tab active={tab === 'Steps'} onClick={() => setTab('Steps')} title={"Steps"}/>
                        <Tab active={tab === 'Terms'} onClick={() => setTab('Terms')} title={"Terms"}/>
                        <Tab active={tab === 'Tools'} onClick={() => setTab('Tools')} title={"Tools"}/>
                    </div>
                    <div>
                        <Tab onClick={() => {
                            setSelected('');
                            setTab('Steps');
                        }} title={"Close"}/>
                    </div>
                </div>
                <div>
                    {tab === 'Steps' && <Steps items={selected_data.implement}/>}
                    {tab === 'Terms' && <Terms items={getItemTerms(selected_data.implement, 'terms')}/>}
                    {tab === 'Tools' && <Tools items={getItemTerms(selected_data.implement, 'tools')}/>}
                </div>
            </div>
        </div>}
    </div>
}