import React from "react";

export function BlogContainer({gray_bg = false, children}) {

    return <div className={`py-10 ${gray_bg ? 'bg-gray-50 border-t border-gray-100' : ''}`}>
        <div className="px-6">
            <div className='py-4 flex-grow'>
                {children}
            </div>
        </div>
    </div>
}