import * as React from "react"
import Page from "../../components/page";
import Header from "../../components/header";
import {BlogContainer} from "../../components/blog/container";
import {BlogMDWrapper} from "../../components/blog/md-wrapper";
import {TestGuide} from "../../components/test-guide";
import {AutomatedTestingTrapezoid} from "../../components/automated-testing-trapezoid";
import {TypesOfTesting} from "../../components/types-of-testing";
import {MarkdownViewer} from "../../components/markdown-viewer";

const markdown = `

`;

export default function BlogTesting() {


    return (
        <Page menu>
            <Header subtitle="Testing" title='A step-by-step guide to a better testing infrastructure'
            />
<div className="container mx-auto">

    <TestGuide />
</div>
            <div className="container mx-auto">
                <AutomatedTestingTrapezoid />
            </div>
            <div className="container mx-auto max-w-4xl">
                <TypesOfTesting />
            </div>
            <BlogContainer>
                <BlogMDWrapper>
                    <div>

                    </div>
                    <MarkdownViewer source={markdown}></MarkdownViewer>
                </BlogMDWrapper>
            </BlogContainer>
        </Page>
    )
};